import { SentryEnvironment } from '@techniek-team/sentry-web';
import { EnvironmentInterface } from './environment.interface';

export const environment: EnvironmentInterface = {
  production: true,
  environment: SentryEnvironment.PRODUCTION,
  release: 'v2.6.13',
  sentryDsn: 'https://f341ed93fc24409b8a408176e636449e@errors.techniek-team.nl//145',

  coreApiUrl: 'https://api.ci.mijnskoleo.nl',
  dashboardApiUrl: 'https://traject.lyceo.nl',

  ssoConfig: {
    authority: 'https://login.lyceo.nl/realms/sso',
    clientId: 'school-dashboard-production',
    redirectUri: 'https://school.lyceo.nl/',
    baseUrl: 'https://school.lyceo.nl/',
  },
};
